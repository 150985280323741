<template>
  <div class="tag">
    <a-tag :color="randomColor()" v-for="item in lists" :key="item.id">
      {{ item.rec_name }}
      <span>{{item.num}}</span>
    </a-tag>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lists: [],
    };
  },
  mounted() {
    this.getTag();
  },

  methods: {
    randomColor() {
      var R = Math.floor(Math.random() * 255);
      var G = Math.floor(Math.random() * 255);
      var B = Math.floor(Math.random() * 255);

      return 'rgb(' + R + ',' + G + ',' + B + ')';
    },
    getTag() {
      return this.$request(Apis.forum.BIAOQIAN)
        .then(data => {
          this.lists = data.data.data;
         
        })
        .catch(e => {})
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.tag {
  .ant-tag {
    color: #000;
    margin-bottom: 10px;
    span {
      background: #fff;
      border-radius: 5px;
      padding: 0 10px;
      margin-top: 1px;
    }
  }
}
</style>