var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag" },
    _vm._l(_vm.lists, function(item) {
      return _c(
        "a-tag",
        { key: item.id, attrs: { color: _vm.randomColor() } },
        [
          _vm._v("\n    " + _vm._s(item.rec_name) + "\n    "),
          _c("span", [_vm._v(_vm._s(item.num))])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }